import { gql } from 'graphql-request'
import useGraphqlMutation from './useGraphqlMutation'

const mutation = gql`
    mutation submitJotform(
        $id: String!
        $input: Json!
        $token: String!
        $type: String
    ) {
        submitJotform(
            id: $id
            input: $input
            token: $token
            type: $type
        ) {
            submissionId
        }
    }
`

const useSubmitJotformMutation = (options) => useGraphqlMutation(mutation, options)

export default useSubmitJotformMutation

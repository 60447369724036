import React, { useState, useEffect } from 'react'
import { useReCaptcha } from 'next-recaptcha-v3'
import { getMessageFromGraphqlError } from '../../util/graphql'
import useJotformQuery from '../hooks/useJotformQuery'
import useJotformFormState from '../hooks/useJotformFormState'
import useSubmitJotformMutation from '../hooks/useSubmitJotformMutation'
import JotformSection from './JotformSection'

const JotformSectionWithState = ({
    spacing,
    formTitle,
    container,
    className,
    background,
    jotformType,
    formSubTitle,
    jotformId = null,
    showFormTitle = true,
}) => {
    const { executeRecaptcha } = useReCaptcha()
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const {
        form,
        error,
        loading,
    } = useJotformQuery(jotformId)
    const formState = useJotformFormState(form, formTitle)
    const { mutateAsync: submitForm, isLoading: isSubmitting } = useSubmitJotformMutation()

    useEffect(() => {
        if (error) {
            setErrorMessage(getMessageFromGraphqlError(error))
        }
    }, [error])

    const handleSubmit = async () => {
        try {
            if (formState.validate()) {
                const token = await executeRecaptcha(`submitJotForm${jotformId}`)
                const input = formState.valuesToInput()
                await submitForm({
                    id: jotformId,
                    title: formTitle,
                    input,
                    token,
                    type: jotformType,
                })
                setIsSubmitted(true)
            }
        } catch (e) {
            setErrorMessage(getMessageFromGraphqlError(e))
        }
    }

    return (
        <JotformSection
            form={form}
            spacing={spacing}
            isLoading={loading}
            formTitle={formTitle}
            formState={formState}
            className={className}
            container={container}
            background={background}
            onSubmit={handleSubmit}
            isSubmitted={isSubmitted}
            isSubmitting={isSubmitting}
            formSubTitle={formSubTitle}
            errorMessage={errorMessage}
            showFormTitle={showFormTitle}
        />
    )
}

export default JotformSectionWithState
